/* 獎項查詢 */
import {
    getAwardsHgaPersonalAPI,
    getAwardsHgaOrgAPI,
    getAwardsLgaPersonalAPI,
    getAwardsLgaOrgAPI,
    getAwardsMdrtPersonalAPI,
    getAwardsMdrtOrgAPI,
    getAwardsIdaPersonalAPI,
    getAwardsIdaOrgAPI,
    getAwardsGoldPersonalAPI,
    getAwardsGoldOrgAPI,
    getAwardsApfPersonalAPI,
    getAwardsApfOrgAPI
} from '@/assets/javascripts/api'

const awardSetting = {
    101: {
        name: 'HGA醫療守護天使',
        value: 'hga',
        api: {
            self: getAwardsHgaPersonalAPI,
            org: getAwardsHgaOrgAPI
        },
        info: ['insuredCount']
    },
    102: {
        name: 'LGA幸福守護家',
        value: 'lga',
        api: {
            self: getAwardsLgaPersonalAPI,
            org: getAwardsLgaOrgAPI
        },
        info: ['benefits']
    },
    103: {
        name: 'MDRT美國百萬圓桌會員',
        value: 'mdrt',
        api: {
            self: getAwardsMdrtPersonalAPI,
            org: getAwardsMdrtOrgAPI
        },
        info: ['fyp', 'fyb']
    },
    104: {
        name: 'IDA國際龍獎',
        value: 'ida',
        api: {
            self: getAwardsIdaPersonalAPI,
            org: getAwardsIdaOrgAPI
        },
        info: ['fyb', 'fyn']
    },
    105: {
        name: '金天勤獎',
        value: 'gold',
        api: {
            self: getAwardsGoldPersonalAPI,
            org: getAwardsGoldOrgAPI
        },
        info: ['fyb', 'fyn'],
        extraInfo: ['fynPerMonth']
    },
    106: {
        name: 'APFinSA亞太保險獎',
        value: 'apf',
        api: {
            self: getAwardsApfPersonalAPI,
            org: getAwardsApfOrgAPI
        },
        info: ['fyp', 'insuredCount'],
        descriptions: [
            '本查詢系統未提供續保率標準，請留意是否有達到申請資格要求。'
        ]
    }
}

const awardInfoSetting = {
    insuredCount: { title: '計績人數', unit: '人', subUnit: '' },
    benefits: { title: '競賽保額(新台幣)', unit: '元', subUnit: '保額' },
    fyp: { title: '競賽保費', unit: '元', subUnit: 'FYP' },
    fyb: { title: '競賽業績', unit: '元', subUnit: 'FYC' },
    fyn: { title: '競實件數', unit: '件', subUnit: '' },
    fynPerMonth: { title: '每月件數標準' }
}

const awardsFilterTitles = {
    contestType: '競賽類型',
    contestId: '競賽名稱',
    contestRule: '競賽規則',
    year: '獎項年度',
    dataType: '業績類別',
    relationMode: '組織種類',
    endDate: '受理月份',
    _seq: [
        'contestType',
        'contestId',
        'contestRule',
        'year',
        'dataType',
        'relationMode',
        'endDate'
    ]
}

const awardsColumn = {
    serialNumber: {
        label: '編號',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        total: 'key',
        position: 'center',
        width: 2,
        widthPx: 50
    },
    supplier: {
        label: '保險公司',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 110
    },
    insNo: {
        label: '保單號碼',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 8,
        widthPx: 150
    },
    mainProductName: {
        label: '主約名稱',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 20,
        widthPx: 160
    },
    productName: {
        label: '商品名稱',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 20,
        widthPx: 160
    },
    receiveDate: {
        label: '受理日',
        sortEnabled: true,
        isFilterable: true,
        type: 'date',
        position: 'center',
        width: 4,
        widthPx: 100
    },
    effectDate: {
        label: '生效日',
        sortEnabled: true,
        isFilterable: true,
        type: 'date',
        position: 'center',
        width: 4,
        widthPx: 100
    },
    commitDate: {
        label: '核實月',
        sortEnabled: true,
        isFilterable: true,
        type: 'date',
        position: 'center',
        width: 4,
        widthPx: 100
    },
    firstCommitDate: {
        label: '首次核實月',
        sortEnabled: true,
        isFilterable: true,
        type: 'date',
        position: 'center',
        width: 4,
        widthPx: 100
    },
    payer: {
        label: '要保人',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 4,
        widthPx: 100
    },
    insured: {
        label: '被保人',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 4,
        widthPx: 100
    },
    insuredCompute: {
        label: '計績人數',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'center',
        width: 4,
        total: 'sum',
        widthPx: 100
    },
    originInsAmount: {
        label: '原始保額',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        width: 14,
        widthPx: 150
    },
    contestInsAmount: {
        label: '競賽保額',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        width: 14,
        widthPx: 150
    },
    salesName: {
        label: '業務員姓名',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        widthPx: 100,
        width: 8
    },
    bgName: {
        label: '營業單位',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        widthPx: 100,
        width: 8
    },
    orgBmGroupName: {
        label: '事業部',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        widthPx: 140,
        width: 8
    },
    orgBmName: {
        label: '部主管',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        widthPx: 100,
        width: 8
    },
    relativeDepth: {
        label: '代數',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'center',
        width: 4,
        widthPx: 50
    },
    insuredCount: {
        label: '計績人數',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        width: 8,
        widthPx: 110,
        unit: '人'
    },
    level: {
        label: '階級',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        widthPx: 140,
        width: 10
    },
    fyn: {
        label: '競賽件數',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        widthPx: 80,
        width: 4,
        total: 'sum',
        unit: '件'
    },
    fynPerMonth: {
        label: '每月件數標準',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        widthPx: 100,
        width: 4
    },
    originFyb: {
        label: '原始業績',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        width: 14,
        widthPx: 120
    },
    contestFyb: {
        label: '競賽業績',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        width: 14,
        widthPx: 120
    },
    contestFybTotal: {
        label: '競賽業績',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        width: 14,
        widthPx: 120
    },
    originFyp: {
        label: '原始保費',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        width: 14,
        widthPx: 120
    },
    contestFyp: {
        label: '競賽保費',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        width: 14,
        widthPx: 120
    },
    status: {
        label: '件數說明',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 8,
        widthPx: 90
    },
    total: {
        label: '總分',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'center',
        widthPx: 100,
        width: 8,
        total: 'sum'
    },
    performanceTotal: {
        label: '業績積分',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'center',
        widthPx: 100,
        width: 8
    },
    hga: {
        label: 'HGA',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'center',
        widthPx: 100,
        width: 8,
        toolTips: {
            hasToolTips: true,
            content: {
                desktop: '醫療守護天使(每完成一階)',
                mobile: '「HGA」醫療守護天使(每完成一階)'
            }
        }
    },
    lga: {
        label: 'LGA',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'center',
        widthPx: 100,
        width: 8,
        toolTips: {
            hasToolTips: true,
            content: {
                desktop: '幸福守護家(每完成一階)',
                mobile: '「LGA」幸福守護家(每完成一階)'
            }
        }
    },
    yearlyManpowerCmDivideCa: {
        label: '年度完成合格\n人員CM/CA',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'center',
        widthPx: 140,
        width: 8
    },
    yearlyManpower: {
        label: '年度完成合格人員',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'center',
        widthPx: 140,
        width: 8
    },
    selfSell: {
        label: '個人引薦合格\n行銷職',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'center',
        widthPx: 140,
        width: 8
    },
    selfManager: {
        label: '個人引薦合格\n經理職',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'center',
        widthPx: 140,
        width: 8
    },
    selfManagement: {
        label: '個人引薦合格\n管理職',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'center',
        widthPx: 140,
        width: 8
    },
    selfOperate: {
        label: '個人引薦合格\n經營職',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'center',
        widthPx: 140,
        width: 8
    },
    underlineSell: {
        label: '轄下引薦合格\n行銷職',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'center',
        widthPx: 140,
        width: 8
    },
    underlineManager: {
        label: '轄下引薦合格\n經理職',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'center',
        widthPx: 140,
        width: 8
    },
    underlineManagement: {
        label: '轄下引薦合格\n管理職',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'center',
        widthPx: 140,
        width: 8
    },
    underlineOperate: {
        label: '轄下引薦合格\n經營職',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'center',
        widthPx: 140,
        width: 8
    },
    promotionOperate: {
        label: '晉升經營職',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'center',
        widthPx: 100,
        width: 8
    },
    promotionManager: {
        label: '晉升經理職',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'center',
        widthPx: 100,
        width: 8
    },
    promotionManagement: {
        label: '晉升管理職',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'center',
        widthPx: 100,
        width: 8
    },
    recommendManpower: {
        label: '引薦合格人力',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'center',
        widthPx: 100,
        width: 8
    },
    promotionOne: {
        label: '晉升一階',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'center',
        widthPx: 100,
        width: 8
    },
    underlinePromotionManagement: {
        label: '轄下晉升\n管理職',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'center',
        widthPx: 100,
        width: 8
    },
    underlinePromotionOperate: {
        label: '轄下晉升\n經營職',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'center',
        widthPx: 100,
        width: 8
    },
    underlinePromotionOne: {
        label: '轄下晉升一階',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'center',
        widthPx: 100,
        width: 8
    },
    underlinePromotionManager: {
        label: '轄下晉升\n經理職',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'center',
        widthPx: 100,
        width: 8
    },
    underlineCaPromotionManager: {
        label: '轄下CA晉升\n經理職',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'center',
        widthPx: 100,
        width: 8
    },
    underlinePerPromotion: {
        label: '轄下每晉升\n一階',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'center',
        widthPx: 100,
        width: 8
    },
    title: {
        label: '職級',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        widthPx: 120,
        width: 8
    }
}

const awardsMode = {
    ida: [
        { label: '依保單', value: 'insurances' },
        { label: '依主附約', value: 'insDetails' }
    ],
    gold: [
        { label: '依保單', value: 'insurances' },
        { label: '依主附約', value: 'insDetails' }
    ],
    apf: [
        { label: '依被保人', value: 'insurances' },
        { label: '依主附約', value: 'insDetails' }
    ]
}

const awardsColumnConfig = {
    hga: {
        self: [
            'serialNumber',
            'supplier',
            'insNo',
            'productName',
            'receiveDate',
            'effectDate',
            'commitDate',
            'payer',
            'insured',
            'insuredCompute'
        ],
        org: [
            'serialNumber',
            'salesName',
            'bgName',
            'orgBmGroupName',
            'orgBmName',
            'relativeDepth',
            'insuredCount',
            'level'
        ]
    },
    lga: {
        self: [
            'serialNumber',
            'supplier',
            'insNo',
            'productName',
            'receiveDate',
            'effectDate',
            'commitDate',
            'payer',
            'insured',
            'originInsAmount',
            'contestInsAmount'
        ],
        org: [
            'serialNumber',
            'salesName',
            'bgName',
            'orgBmGroupName',
            'orgBmName',
            'relativeDepth',
            'contestInsAmount',
            'level'
        ]
    },
    ida: {
        mode: true,
        self: {
            insurances: [
                'serialNumber',
                'supplier',
                'insNo',
                'mainProductName',
                'receiveDate',
                'effectDate',
                'firstCommitDate',
                'payer',
                'insured',
                'contestFybTotal',
                'fyn',
                'status'
            ],
            insDetails: [
                'serialNumber',
                'supplier',
                'insNo',
                'productName',
                'receiveDate',
                'effectDate',
                'commitDate',
                'payer',
                'insured',
                'contestFyb'
            ]
        },
        org: [
            'serialNumber',
            'salesName',
            'bgName',
            'orgBmGroupName',
            'orgBmName',
            'relativeDepth',
            'contestFyb',
            'fyn',
            'level'
        ]
    },
    mdrt: {
        self: [
            'serialNumber',
            'supplier',
            'insNo',
            'productName',
            'receiveDate',
            'effectDate',
            'commitDate',
            'payer',
            'insured',
            'originFyp',
            'contestFyp',
            'originFyb',
            'contestFyb'
        ],
        org: [
            'serialNumber',
            'salesName',
            'bgName',
            'orgBmGroupName',
            'orgBmName',
            'relativeDepth',
            'contestFyp',
            'contestFyb',
            'level'
        ]
    },
    apf: {
        mode: true,
        self: {
            insurances: [
                'serialNumber',
                'supplier',
                'insNo',
                'mainProductName',
                'receiveDate',
                'effectDate',
                'firstCommitDate',
                'payer',
                'insured',
                'insuredCompute'
            ],
            insDetails: [
                'serialNumber',
                'supplier',
                'insNo',
                'productName',
                'receiveDate',
                'effectDate',
                'commitDate',
                'payer',
                'insured',
                'originFyp',
                'contestFyp'
            ]
        },
        org: [
            'serialNumber',
            'salesName',
            'bgName',
            'orgBmGroupName',
            'orgBmName',
            'relativeDepth',
            'contestFyp',
            'insuredCount',
            'level'
        ]
    },
    gold: {
        mode: true,
        self: {
            insurances: [
                'serialNumber',
                'supplier',
                'insNo',
                'mainProductName',
                'receiveDate',
                'effectDate',
                'firstCommitDate',
                'payer',
                'insured',
                'contestFybTotal',
                'fyn',
                'status'
            ],
            insDetails: [
                'serialNumber',
                'supplier',
                'insNo',
                'productName',
                'receiveDate',
                'effectDate',
                'commitDate',
                'payer',
                'insured',
                'contestFyb'
            ]
        },
        org: [
            'serialNumber',
            'salesName',
            'bgName',
            'orgBmGroupName',
            'orgBmName',
            'relativeDepth',
            'contestFyb',
            'fyn',
            'fynPerMonth',
            'level'
        ]
    },
    group: {
        sell: {
            self: [
                'serialNumber',
                'title',
                'total',
                'performanceTotal',
                'hga',
                'lga',
                'promotionOne',
                'promotionManager',
                'promotionManagement',
                'promotionOperate',
                'recommendManpower'
            ],
            org: [
                'serialNumber',
                'salesName',
                'bgName',
                'orgBmGroupName',
                'orgBmName',
                'relativeDepth',
                'total'
            ]
        },
        manager: {
            self: [
                'serialNumber',
                'bgName',
                'orgBmName',
                'title',
                'total',
                'performanceTotal',
                'hga',
                'lga',
                'yearlyManpower',
                'selfSell',
                'selfManager',
                'selfManagement',
                'selfOperate',
                'underlineSell',
                'underlineManager',
                'underlineManagement',
                'underlineOperate',
                'promotionOne',
                'promotionManagement',
                'promotionOperate',
                'underlinePromotionManager',
                'underlinePromotionManagement',
                'underlinePromotionOperate',
                'underlinePerPromotion'
            ],
            org: [
                'serialNumber',
                'salesName',
                'bgName',
                'orgBmGroupName',
                'orgBmName',
                'relativeDepth',
                'total'
            ]
        },
        operate: {
            self: [
                'serialNumber',
                'bgName',
                'orgBmName',
                'title',
                'total',
                'performanceTotal',
                'hga',
                'lga',
                'yearlyManpowerCmDivideCa',
                'selfSell',
                'selfManager',
                'selfManagement',
                'selfOperate',
                'underlineSell',
                'underlineManager',
                'underlineManagement',
                'underlineOperate',
                'promotionOperate',
                'promotionOne',
                'underlinePromotionManagement',
                'underlinePromotionOperate',
                'underlinePromotionOne',
                'underlineCaPromotionManager'
            ],
            org: [
                'serialNumber',
                'salesName',
                'bgName',
                'orgBmGroupName',
                'orgBmName',
                'relativeDepth',
                'total'
            ]
        }
    }
}

const awardsSortDefault = {
    hga: {
        self: { sort: 'desc', sortBy: 'insuredCompute' },
        org: { sort: 'desc', sortBy: 'insuredCount' }
    },
    lga: {
        self: { sort: 'desc', sortBy: 'contestInsAmount' },
        org: { sort: 'desc', sortBy: 'contestInsAmount' }
    },
    ida: {
        mode: true,
        self: {
            insurances: { sort: 'desc', sortBy: 'contestFybTotal' },
            insDetails: { sort: 'desc', sortBy: 'contestFyb' }
        },
        org: { sort: 'desc', sortBy: 'contestFyb' }
    },
    mdrt: {
        self: { sort: 'desc', sortBy: 'contestFyb' },
        org: { sort: 'desc', sortBy: 'contestFyb' }
    },
    apf: {
        mode: true,
        self: {
            insurances: { sort: 'desc', sortBy: 'insuredCompute' },
            insDetails: { sort: 'desc', sortBy: 'contestFyp' }
        },
        org: { sort: 'desc', sortBy: 'contestFyp' }
    },
    gold: {
        mode: true,
        self: {
            insurances: { sort: 'desc', sortBy: 'contestFybTotal' },
            insDetails: { sort: 'desc', sortBy: 'contestFyb' }
        },
        org: { sort: 'desc', sortBy: 'contestFyb' }
    },
    group: {
        self: { sort: 'desc', sortBy: 'total' },
        org: { sort: 'desc', sortBy: 'total' }
    }
}

const awardsDetailColumn = {
    serialNumber: {
        label: '編號',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        position: 'center',
        total: 'key',
        width: 0,
        widthPx: 0
    },
    insNo: {
        label: '保單號碼',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'left',
        width: 8,
        widthPx: 140
    },
    supplier: {
        label: '保險公司',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        width: 8,
        widthPx: 110
    },
    payer: {
        label: '要保人',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        width: 8,
        widthPx: 100
    },
    receiveDate: {
        label: '受理日',
        sortEnabled: false,
        isFilterable: false,
        type: 'date',
        position: 'center',
        width: 8,
        widthPx: 100
    },
    effectDate: {
        label: '生效日',
        sortEnabled: false,
        isFilterable: false,
        type: 'date',
        position: 'center',
        width: 8,
        widthPx: 100
    },
    chgDate: {
        label: '異動日',
        sortEnabled: false,
        isFilterable: false,
        type: 'date',
        position: 'center',
        width: 8,
        widthPx: 100
    },
    registerDate: {
        label: '登錄日',
        sortEnabled: false,
        isFilterable: false,
        type: 'date',
        position: 'center',
        widthPx: 100,
        width: 8
    },
    contestFyb: {
        label: '競賽業績',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        position: 'right',
        total: 'sum',
        width: 8,
        widthPx: 120
    },
    bgName: {
        label: '營業單位',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'left',
        widthPx: 100,
        width: 1
    },
    salesName: {
        label: '業務員姓名',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        widthPx: 100,
        width: 8
    },
    orgBmName: {
        label: '部主管',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        width: 8,
        widthPx: 90
    },
    level: {
        label: '等級',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        widthPx: 140,
        width: 1
    },
    title: {
        label: '職級',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        widthPx: 120,
        width: 8
    },
    score: {
        label: '分數',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        position: 'center',
        widthPx: 100,
        width: 8
    },
    remark: {
        label: '備註',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        widthPx: 120,
        width: 8
    },
    recommendPerson: {
        label: '引薦人',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        widthPx: 100,
        width: 8
    },
    recommendedPerson: {
        label: '被引薦人',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        widthPx: 100,
        width: 8
    },
    recommendedPersonTitle: {
        label: '被引薦人職級',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        widthPx: 100,
        width: 8
    },
    insuredCount: {
        label: '被保險人人數',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        total: 'sum',
        position: 'right',
        widthPx: 100,
        width: 8
    },
    contestInsAmount: {
        label: '競賽保額',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        position: 'right',
        total: 'sum',
        width: 8,
        widthPx: 100
    }
}
const awardsDetailColumnConfig = {
    sell: {
        self: {
            performanceTotal: [
                'serialNumber',
                'insNo',
                'supplier',
                'payer',
                'receiveDate',
                'effectDate',
                'contestFyb'
            ],
            hga: ['serialNumber', 'bgName', 'salesName', 'level'],
            lga: ['serialNumber', 'bgName', 'salesName', 'level'],
            promotionOne: [
                'serialNumber',
                'effectDate',
                'title',
                'chgDate',
                'score',
                'remark'
            ],
            promotionManager: [
                'serialNumber',
                'effectDate',
                'title',
                'chgDate',
                'score',
                'remark'
            ],
            promotionManagement: [
                'serialNumber',
                'effectDate',
                'title',
                'chgDate',
                'score',
                'remark'
            ],
            promotionOperate: [
                'serialNumber',
                'effectDate',
                'title',
                'chgDate',
                'score',
                'remark'
            ],
            recommendManpower: [
                'serialNumber',
                'bgName',
                'orgBmName',
                'salesName',
                'registerDate',
                'contestFyb'
            ]
        }
    },
    manager: {
        self: {
            performanceTotal: [
                'serialNumber',
                'insNo',
                'supplier',
                'payer',
                'receiveDate',
                'effectDate',
                'contestFyb'
            ],
            hga: ['serialNumber', 'bgName', 'salesName', 'level'],
            lga: ['serialNumber', 'bgName', 'salesName', 'level'],
            yearlyManpower: [
                'serialNumber',
                'recommendedPerson',
                'recommendedPersonTitle',
                'registerDate',
                'contestFyb'
            ],
            selfSell: [
                'serialNumber',
                'recommendedPerson',
                'recommendedPersonTitle',
                'registerDate',
                'contestFyb'
            ],
            selfManager: [
                'serialNumber',
                'recommendedPerson',
                'recommendedPersonTitle',
                'registerDate',
                'contestFyb'
            ],
            selfManagement: [
                'serialNumber',
                'recommendedPerson',
                'recommendedPersonTitle',
                'registerDate',
                'contestFyb'
            ],
            selfOperate: [
                'serialNumber',
                'recommendedPerson',
                'recommendedPersonTitle',
                'registerDate',
                'contestFyb'
            ],
            promotionOne: [
                'serialNumber',
                'effectDate',
                'title',
                'chgDate',
                'score',
                'remark'
            ],
            promotionManagement: [
                'serialNumber',
                'effectDate',
                'title',
                'chgDate',
                'score',
                'remark'
            ],
            promotionOperate: [
                'serialNumber',
                'effectDate',
                'title',
                'chgDate',
                'score',
                'remark'
            ]
        },
        underline: {
            performanceTotal: [
                'serialNumber',
                'bgName',
                'orgBmName',
                'salesName',
                'title',
                'contestFyb'
            ],
            hga: [
                'serialNumber',
                'bgName',
                'orgBmName',
                'salesName',
                'title',
                'insuredCount',
                'level'
            ],
            lga: [
                'serialNumber',
                'bgName',
                'orgBmName',
                'salesName',
                'title',
                'contestInsAmount',
                'level'
            ],
            yearlyManpower: [
                'serialNumber',
                'bgName',
                'orgBmName',
                'recommendPerson',
                'recommendedPerson',
                'recommendedPersonTitle',
                'contestFyb'
            ],
            underlineSell: [
                'serialNumber',
                'bgName',
                'orgBmName',
                'recommendPerson',
                'recommendedPerson',
                'recommendedPersonTitle',
                'contestFyb'
            ],
            underlineManager: [
                'serialNumber',
                'bgName',
                'orgBmName',
                'recommendPerson',
                'recommendedPerson',
                'recommendedPersonTitle',
                'contestFyb'
            ],
            underlineManagement: [
                'serialNumber',
                'bgName',
                'orgBmName',
                'recommendPerson',
                'recommendedPerson',
                'recommendedPersonTitle',
                'contestFyb'
            ],
            underlineOperate: [
                'serialNumber',
                'bgName',
                'orgBmName',
                'recommendPerson',
                'recommendedPerson',
                'recommendedPersonTitle',
                'contestFyb'
            ],
            underlinePromotionManager: [
                'serialNumber',
                'effectDate',
                'salesName',
                'title',
                'chgDate',
                'score',
                'remark'
            ],
            underlinePromotionManagement: [
                'serialNumber',
                'effectDate',
                'salesName',
                'title',
                'chgDate',
                'score',
                'remark'
            ],
            underlinePromotionOperate: [
                'serialNumber',
                'effectDate',
                'salesName',
                'title',
                'chgDate',
                'score',
                'remark'
            ],
            underlinePerPromotion: [
                'serialNumber',
                'effectDate',
                'salesName',
                'title',
                'chgDate',
                'score',
                'remark'
            ]
        }
    },
    operate: {
        self: {
            performanceTotal: [
                'serialNumber',
                'insNo',
                'supplier',
                'payer',
                'receiveDate',
                'effectDate',
                'contestFyb'
            ],
            hga: ['serialNumber', 'bgName', 'salesName', 'level'],
            lga: ['serialNumber', 'bgName', 'salesName', 'level'],
            yearlyManpowerCmDivideCa: [
                'serialNumber',
                'recommendedPerson',
                'recommendedPersonTitle',
                'registerDate',
                'contestFyb'
            ],
            selfSell: [
                'serialNumber',
                'recommendedPerson',
                'recommendedPersonTitle',
                'registerDate',
                'contestFyb'
            ],
            selfManager: [
                'serialNumber',
                'recommendedPerson',
                'recommendedPersonTitle',
                'registerDate',
                'contestFyb'
            ],
            selfManagement: [
                'serialNumber',
                'recommendedPerson',
                'recommendedPersonTitle',
                'registerDate',
                'contestFyb'
            ],
            selfOperate: [
                'serialNumber',
                'recommendedPerson',
                'recommendedPersonTitle',
                'registerDate',
                'contestFyb'
            ],
            promotionOne: [
                'serialNumber',
                'effectDate',
                'title',
                'chgDate',
                'score',
                'remark'
            ],
            promotionOperate: [
                'serialNumber',
                'effectDate',
                'title',
                'chgDate',
                'score',
                'remark'
            ]
        },
        underline: {
            performanceTotal: [
                'serialNumber',
                'bgName',
                'orgBmName',
                'salesName',
                'title',
                'contestFyb'
            ],
            hga: [
                'serialNumber',
                'bgName',
                'orgBmName',
                'salesName',
                'title',
                'insuredCount',
                'level'
            ],
            lga: [
                'serialNumber',
                'bgName',
                'orgBmName',
                'salesName',
                'title',
                'contestInsAmount',
                'level'
            ],
            yearlyManpowerCmDivideCa: [
                'serialNumber',
                'bgName',
                'orgBmName',
                'recommendPerson',
                'recommendedPerson',
                'recommendedPersonTitle',
                'contestFyb'
            ],
            underlineSell: [
                'serialNumber',
                'bgName',
                'orgBmName',
                'recommendPerson',
                'recommendedPerson',
                'recommendedPersonTitle',
                'contestFyb'
            ],
            underlineManager: [
                'serialNumber',
                'bgName',
                'orgBmName',
                'recommendPerson',
                'recommendedPerson',
                'recommendedPersonTitle',
                'contestFyb'
            ],
            underlineManagement: [
                'serialNumber',
                'bgName',
                'orgBmName',
                'recommendPerson',
                'recommendedPerson',
                'recommendedPersonTitle',
                'contestFyb'
            ],
            underlineOperate: [
                'serialNumber',
                'bgName',
                'orgBmName',
                'recommendPerson',
                'recommendedPerson',
                'recommendedPersonTitle',
                'contestFyb'
            ],
            underlineCaPromotionManager: [
                'serialNumber',
                'effectDate',
                'salesName',
                'title',
                'chgDate',
                'score',
                'remark'
            ],
            underlinePromotionManagement: [
                'serialNumber',
                'effectDate',
                'salesName',
                'title',
                'chgDate',
                'score',
                'remark'
            ],
            underlinePromotionOperate: [
                'serialNumber',
                'effectDate',
                'salesName',
                'title',
                'chgDate',
                'score',
                'remark'
            ],
            underlinePromotionOne: [
                'serialNumber',
                'effectDate',
                'salesName',
                'title',
                'chgDate',
                'score',
                'remark'
            ]
        }
    }
}

const awardsDetailInfo = {
    sell: {
        performanceTotal: {
            condition: {
                title: '業績總積分',
                tooltip: '為「個人業績積分」的加總'
            },
            self: '個人業績積分'
        },
        hga: {
            condition: {
                title: '被保險人總人數',
                tooltip: '為「個人被保險人人數」的加總'
            },
            self: '個人被保險人人數'
        },
        lga: {
            condition: {
                title: '被保險人總人數',
                tooltip: '為「個人被保險人人數」的加總'
            },
            self: '個人被保險人人數'
        },
        promotionOne: {
            condition: {
                title: '晉升總積分',
                tooltip: '為「個人晉升積分」的加總'
            },
            self: '個人晉升積分'
        },
        promotionManager: {
            condition: {
                title: '晉升總積分',
                tooltip: '為「個人晉升積分」的加總'
            },
            self: '個人晉升積分'
        },
        promotionManagement: {
            condition: {
                title: '晉升總積分',
                tooltip: '為「個人晉升積分」的加總'
            },
            self: '個人晉升積分'
        },
        promotionOperate: {
            condition: {
                title: '晉升總積分',
                tooltip: '為「個人晉升積分」的加總'
            },
            self: '個人晉升積分'
        },
        recommendManpower: {
            condition: {
                title: '引薦合格人力總積分',
                tooltip: '為「個人引薦合格人力積分」的加總'
            },
            self: '個人引薦合格人力積分'
        }
    },
    manager: {
        performanceTotal: {
            condition: {
                title: '業績總積分',
                tooltip: '為「個人業績積分」與「轄下業績積分」的加總'
            },
            self: '個人業績積分',
            underline: '轄下業績積分'
        },
        hga: {
            condition: {
                title: '總積分',
                tooltip: '為「個人積分」與「轄下積分」的加總'
            },
            self: '個人積分',
            underline: '轄下積分'
        },
        lga: {
            condition: {
                title: '保額總積分',
                tooltip: '為「個人保額積分」與「轄下保額積分」的加總'
            },
            self: '個人保額積分',
            underline: '轄下保額積分'
        },
        yearlyManpower: {
            condition: {
                title: '完成合格人員總積分',
                tooltip:
                    '為「個人完成合格人員積分」與「轄下完成合格人員積分」的加總'
            },
            self: '個人完成合格人員積分',
            underline: '轄下完成合格人員積分'
        },
        selfSell: {
            condition: {
                title: '完成合格人員總積分',
                tooltip: '為「個人完成合格人員積分」的加總'
            },
            self: '個人完成合格人員積分'
        },
        selfManager: {
            condition: {
                title: '完成合格人員總積分',
                tooltip: '為「個人完成合格人員積分」的加總'
            },
            self: '個人完成合格人員積分'
        },
        selfManagement: {
            condition: {
                title: '完成合格人員總積分',
                tooltip: '為「個人完成合格人員積分」的加總'
            },
            self: '個人完成合格人員積分'
        },
        selfOperate: {
            condition: {
                title: '完成合格人員總積分',
                tooltip: '為「個人完成合格人員積分」的加總'
            },
            self: '個人完成合格人員積分'
        },
        underlineSell: {
            condition: {
                title: '完成合格人員總積分',
                tooltip: '為「轄下完成合格人員積分」的加總'
            },
            underline: '轄下完成合格人員積分'
        },
        underlineManager: {
            condition: {
                title: '完成合格人員總積分',
                tooltip: '為「轄下完成合格人員積分」的加總'
            },
            underline: '轄下完成合格人員積分'
        },
        underlineManagement: {
            condition: {
                title: '完成合格人員總積分',
                tooltip: '為「轄下完成合格人員積分」的加總'
            },
            underline: '轄下完成合格人員積分'
        },
        underlineOperate: {
            condition: {
                title: '完成合格人員總積分',
                tooltip: '為「轄下完成合格人員積分」的加總'
            },
            underline: '轄下完成合格人員積分'
        },
        promotionOne: {
            condition: {
                title: '晉升總積分',
                tooltip: '為「個人晉升積分」的加總'
            },
            self: '個人晉升積分'
        },
        promotionManagement: {
            condition: {
                title: '晉升總積分',
                tooltip: '為「個人晉升積分」的加總'
            },
            self: '個人晉升積分'
        },
        promotionOperate: {
            condition: {
                title: '晉升總積分',
                tooltip: '為「個人晉升積分」的加總'
            },
            self: '個人晉升積分'
        },
        underlinePromotionManager: {
            condition: {
                title: '晉升總積分',
                tooltip: '為「轄下晉升積分」的加總'
            },
            underline: '轄下晉升積分'
        },
        underlinePromotionManagement: {
            condition: {
                title: '晉升總積分',
                tooltip: '為「轄下晉升積分」的加總'
            },
            underline: '轄下晉升積分'
        },
        underlinePromotionOperate: {
            condition: {
                title: '晉升總積分',
                tooltip: '為「轄下晉升積分」的加總'
            },
            underline: '轄下晉升積分'
        },
        underlinePerPromotion: {
            condition: {
                title: '晉升總積分',
                tooltip: '為「轄下晉升積分」的加總'
            },
            underline: '轄下晉升積分'
        }
    },
    operate: {
        performanceTotal: {
            condition: {
                title: '業績總積分',
                tooltip: '為「個人業績積分」與「轄下業績積分」的加總'
            },
            self: '個人業績積分',
            underline: '轄下業績積分'
        },
        hga: {
            condition: {
                title: '總積分',
                tooltip: '為「個人積分」與「轄下積分」的加總'
            },
            self: '個人積分',
            underline: '轄下積分'
        },
        lga: {
            condition: {
                title: '保額總積分',
                tooltip: '為「個人保額積分」與「轄下保額積分」的加總'
            },
            self: '個人保額積分',
            underline: '轄下保額積分'
        },
        yearlyManpowerCmDivideCa: {
            condition: {
                title: '完成合格人員總積分',
                tooltip:
                    '為「個人完成合格人員積分」與「轄下完成合格人員積分」的加總'
            },
            self: '個人完成合格人員積分',
            underline: '轄下完成合格人員積分'
        },
        selfSell: {
            condition: {
                title: '完成合格人員總積分',
                tooltip: '為「個人完成合格人員積分」的加總'
            },
            self: '個人完成合格人員積分'
        },
        selfManager: {
            condition: {
                title: '完成合格人員總積分',
                tooltip: '為「個人完成合格人員積分」的加總'
            },
            self: '個人完成合格人員積分'
        },
        selfManagement: {
            condition: {
                title: '完成合格人員總積分',
                tooltip: '為「個人完成合格人員積分」的加總'
            },
            self: '個人完成合格人員積分'
        },
        selfOperate: {
            condition: {
                title: '完成合格人員總積分',
                tooltip: '為「個人完成合格人員積分」的加總'
            },
            self: '個人完成合格人員積分'
        },
        underlineSell: {
            condition: {
                title: '完成合格人員總積分',
                tooltip: '為「轄下完成合格人員積分」的加總'
            },
            underline: '轄下完成合格人員積分'
        },
        underlineManager: {
            condition: {
                title: '完成合格人員總積分',
                tooltip: '為「轄下完成合格人員積分」的加總'
            },
            underline: '轄下完成合格人員積分'
        },
        underlineManagement: {
            condition: {
                title: '完成合格人員總積分',
                tooltip: '為「轄下完成合格人員積分」的加總'
            },
            underline: '轄下完成合格人員積分'
        },
        underlineOperate: {
            condition: {
                title: '完成合格人員總積分',
                tooltip: '為「轄下完成合格人員積分」的加總'
            },
            underline: '轄下完成合格人員積分'
        },
        promotionOne: {
            condition: {
                title: '晉升總積分',
                tooltip: '為「個人晉升積分」的加總'
            },
            self: '個人晉升積分'
        },
        promotionOperate: {
            condition: {
                title: '晉升總積分',
                tooltip: '為「個人晉升積分」的加總'
            },
            self: '個人晉升積分'
        },
        underlineCaPromotionManager: {
            condition: {
                title: '晉升總積分',
                tooltip: '為「轄下晉升積分」的加總'
            },
            underline: '轄下晉升積分'
        },
        underlinePromotionManagement: {
            condition: {
                title: '晉升總積分',
                tooltip: '為「轄下晉升積分」的加總'
            },
            underline: '轄下晉升積分'
        },
        underlinePromotionOperate: {
            condition: {
                title: '晉升總積分',
                tooltip: '為「轄下晉升積分」的加總'
            },
            underline: '轄下晉升積分'
        },
        underlinePromotionOne: {
            condition: {
                title: '晉升總積分',
                tooltip: '為「轄下晉升積分」的加總'
            },
            underline: '轄下晉升積分'
        }
    }
}

const awardDescription = [
    '因本系統未包含所有發佣狀況，因此僅供同仁作為參考用途，實際業績結果以行政公文記載為主。',
    '如有個人介紹報備已填寫聯繫單但尚未回歸之業績，需待保險公司完成服務權轉換作業後才會顯示在獎項系統清單中。'
]

export {
    awardSetting,
    awardInfoSetting,
    awardsFilterTitles,
    awardsColumn,
    awardsColumnConfig,
    awardsSortDefault,
    awardsDetailColumn,
    awardsDetailColumnConfig,
    awardsDetailInfo,
    awardsMode,
    awardDescription
}
